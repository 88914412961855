<template>
  <Content>
    <template #contentTitle>{{ $t('menu.storage_inbound_shipping') }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <StepsRow :current="2" />
        <a-row :gutter="[8, 8]">
          <a-col :span="24">{{ $t('warehouse.in_warehouse_plan_no') }}: {{ pageState.planNo || '-' }}</a-col>
          <a-col :span="24">{{ $t('warehouse.in_plan_name') }}: {{ pageState.planName || '-' }}</a-col>
          <a-col :span="24" :lg="12" :xl="8">
            {{ $t('warehouse.in_target_warehouse') }}: 
            <span v-if="pageState.to">{{ pageState.to.no }}({{ pageState.to.name }})</span>
            <span v-else>{{ '-' }}</span>
          </a-col>
          <a-col :span="24" :lg="12" :xl="16">
            {{ $t('warehouse.warehouse_address') }}: 
            <template v-if="pageState.to">
              <span>{{ getFullAddress(pageState.to) }}</span>
              <span class="ml-1">({{ $t("logistics.contacts") }}: {{ getLinkman(pageState.to) }})</span>
            </template>
            <span v-else>{{ '-' }}</span>
          </a-col>
          <a-col :span="24" :lg="12" :xl="8">
            {{ $t('warehouse.originate') }}: 
            <span v-if="pageState.from">{{ pageState.from.name }}</span>
            <span v-else>{{ '-' }}</span>
          </a-col>
          <a-col :span="24" :lg="12" :xl="16">
            {{ $t('warehouse.from_product_address') }}: 
            <template v-if="pageState.from">
              <span>{{ getFullAddress(pageState.from) }}</span>
              <span class="ml-1" v-if="pageState.from.isWarehouse">({{ $t("logistics.contacts") }}: {{ getLinkman(pageState.from) }})</span>
            </template>
            <span v-else>{{ '-' }}</span>
          </a-col>
        </a-row>
        <a-table
          style="overflow-x: auto;"
          class="mt-3"
          :columns="columns"
          :data-source="boxList"
          size="small"
          :expandIconAsCell="false"
          :expandIconColumnIndex="2"
          :pagination="pagination"
          :row-key="(record) => record.detailId"
          @change="(page) => pagination.current = page.current"
        >
          <template #serialNumber="{ index }">
            {{ ((pagination.current - 1) * pagination.pageSize) + (index + 1) }}
          </template>
          <template #boxNoTitle>
            <div>{{ $t('warehouse.box_no') }}</div>
            <div>{{ $t('common.user_defined_box_no') }}</div>
          </template>
          <template #boxNo="{ record }">
            <div>
              {{ record.box.boxNo || '-' }}
              <CTagMixedOrSingle :productKindCount="record.box.container.productKindCount * 1"></CTagMixedOrSingle>
            </div>
            <div>
              <UserDefinedBoxNo :boxId="record.box.whBoxId" :userDefinedBoxNo="record.box.selfBoxNo"></UserDefinedBoxNo>
            </div>
          </template>
          <template #expandIcon="{ expanded, record, onExpand }">
            <CHaveProductInformation :disabled="!record.box.container?.hasRelationProduct" :open="expanded" @click="e => onExpand(record, e)" />
          </template>
          <template #encasementSize="{ record }">
            <span v-if="record.box.container">{{ record.box.container.containerLength }}x{{ record.box.container.containerWidth }}x{{ record.box.container.containerHeight }}cm</span>
            <span v-else>-</span>
          </template>
          <template #encasementWeight="{ record }">
            <span v-if="record.box.container">{{ gToKg(record.box.container.containerWeight) }}kg</span>
            <span v-else>-</span>
          </template>
          <template #encasementCount="{ record }">
            {{ record.box.container ? record.box.container.containerCount : '-' }} Unit
          </template>
          <template #operationTitle>
            <a-button type="primary" ghost :loading="printLoadingId === 'allBox'" :disabled="!!printLoadingId" @click="handleBoxPrint()">{{ $t('common.bulk_print_box_label') }}</a-button>
          </template>
          <template #operation="{ record }">
            <a-button type="primary" ghost :loading="printLoadingId === record.detailId" :disabled="!!printLoadingId" @click="handleBoxPrint(record.detailId)">{{ $t('common.print_box_label') }}</a-button>
          </template>
          <template #title>
            <a-row :gutter="16">
              <a-col>
                <a-select style="width: 200px;" v-model:value="formState.logisticsType" :disabled="pageState.isDeliver" :placeholder="$t('warehouse.logistics_way')">
                  <a-select-option
                    v-for="(item, index) in logisticsWayEnum"
                    :key="index"
                    :value="item"
                    :title="$t($enumLangkey('logisticsWay', item))"
                  >{{ $t($enumLangkey('logisticsWay', item)) }}</a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-select style="width: 200px;" v-model:value="formState.deliveryType" :disabled="pageState.isDeliver" :placeholder="$t('warehouse.delivery_way')">
                  <a-select-option
                    v-for="(item, index) in deliveryWayEnum"
                    :key="index"
                    :value="item"
                    :title="$t($enumLangkey('deliveryWay', item))"
                  >{{ $t($enumLangkey('deliveryWay', item)) }}</a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-button type="primary" :disabled="pageState.isDeliver" @click="handleOpenPayModal('init')">{{ $t('warehouse.despatch_and_pay') }}</a-button>
              </a-col>
              <a-col v-if="pageState.isDeliver && pageState.isNeedPay">
                <a-button type="primary" @click="handleOpenPayModal('repay')">{{ $t('finance.immediate_payment') }}</a-button>
              </a-col>
              <a-col>
                <a-button type="primary" :disabled="!pageState.isDeliver" @click="handleOpenTrackModal">{{ $t('warehouse.tracking_shipment') }}</a-button>
              </a-col>
            </a-row>
          </template>
          <template #footer>
            <a-row :gutter="16" type="flex" justify="space-around">
              <a-col>{{ $t('common.total') }}: {{ pageState.total ? pageState.total.plan.totalContainerBoxCount : '-' }}{{ $t('warehouse.box') }}</a-col>
              <a-col>{{ pageState.total ? cmCubicToM(pageState.total.plan.totalVolume) : '-' }}m<sup>3</sup></a-col>
              <a-col>{{ pageState.total ? gToKg(pageState.total.plan.totalWeight) : '-' }}kg</a-col>
              <a-col>{{ pageState.total ? pageState.total.plan.totalWarehouseCount : '-' }}Unit</a-col>
            </a-row>
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.box.items"
              :pagination="false"
              :row-key="(record, index) => index"
              size="small"
            >
              <template #productInfo="{ record }">
                <a-row :gutter="8" type="flex" align="middle">
                  <a-col>
                    <div class="table-list-img-common">
                      <c-image
                        :src="record.productImgUrl"
                        :thumbWidth="600"
                        :thumbHeight="600"
                      />
                    </div>
                  </a-col>
                  <a-col flex="1">
                    <div>{{ record.productName }}</div>
                    <div>{{ record.productNo }}</div>
                  </a-col>
                </a-row>
              </template>
              <template #encasementCount="{ record }">
                {{ record.containerCount }} Unit
              </template>
              <template #operation="{ record }">
                <a-button type="primary" :loading="printProductLoading" ghost @click="handleProductLabelPrint(record)">{{ $t('warehouse.print_product_labels') }}</a-button>
              </template>
            </a-table>
          </template>
        </a-table>
        <CFee v-if="pageState.isNeedPay && fee" class="mt-5" :totalFee="fee.totalFee" :fees="fee.fees" />
        <CFee v-if="!pageState.isNeedPay && pageState.fee" class="mt-5" :totalFee="pageState.fee.totalFee" :fees="pageState.fee.fees" />
        <a-row class="mt-5 mb-3 mr-3" type="flex" justify="end">
          <a-col><a-button type="primary" @click="handleToList">{{ $t('warehouse.process_other_warehousing_plans') }}</a-button></a-col>
        </a-row>
      </a-spin>
      <Payment
        v-if="fee"
        ref="paymentRef"
        v-model:visible="payModalState.visible"
        :loading="payModalState.loading"
        :currencyId="fee.totalFee.currencyId"
        :orderNo="pageState.planNo"
        :unpaidSymbol="fee.totalFee.symbol"
        :unpaidSum="fee.totalFee.value"
        :payModalTitleType="9"
        :buttonTextType="payType === 'init' ? 1 : null"
        @handlePayFee="handleConfirmShipping"
      />
      <TrackingShipmentsModal ref="trackingShipmentsModalRef" />
    </template>
  </Content>
</template>

<script>
import { defineComponent, nextTick, onMounted, reactive, ref, toRefs } from 'vue';
import { Button, Col, Input, Row, Select, Spin, Table, message } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CImage from '@/views/components/CImage.vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue';
import CTagMixedOrSingle from '@/views/components/CTagMixedOrSingle.vue';
import UserDefinedBoxNo from '@/views/components/UserDefinedBoxNo.vue';
import CFee from '@/views/components/CFee.vue';
import Payment from '@/views/components/Payment.vue';
import StepsRow from './components/StepsRow.vue';
import TrackingShipmentsModal from './components/TrackingShipmentsModal.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { gToKg, cmCubicToM, setFormStateValue, getAddressByLanguageV2, getLinkman } from '@/utils/general.js';
import { useTab } from "@/hooks/tabs/index";
import localPrint from "@/utils/localPrint";
import {
  logisticsWay as logisticsWayEnum,
  deliveryWay as deliveryWayEnum,
  warehouseType as warehouseTypeEnum,
} from '@/enum/enum.json';
import {
  getStorageDetail,
  getInWarehousePlanBoxPrint,
  getInWarehpusePlanBoxBatchPrint,
  getInWarehousePlanFee,
  updateTransInfo,
  payInWarehousePlan,
} from '@/api/modules/consignment/inbound.js';
import filters from "@/filters";

export default defineComponent({
  name: 'storage_inbound_shipping',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AButton: Button,
    ATable: Table,
    ASelect: Select,
    ASelectOption: Select.Option,
    Content,
    CImage,
    CHaveProductInformation,
    CTagMixedOrSingle,
    UserDefinedBoxNo,
    CFee,
    Payment,
    StepsRow,
    TrackingShipmentsModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();

    const paymentRef = ref(null);
    const trackingShipmentsModalRef = ref(null);

    const state = reactive({
      loading: false,
      pageState: {
        planNo: null,
        planName: null,
        from: null,
        to: null,
        // 是否已经关联运输
        hasRelationTrans: false,
        // 是否显示上一步按钮
        isShowPrevButton: false,
        // 是否可以取消
        isCanCancel: false,
        // 是否已经发运
        isDeliver: false,
        // 是否需要支付
        isNeedPay: null,
        // 仓库类型
        warehouseType: null,
        // 总计
        total: null,
        // 费用
        fee: null,
      },
      // 费用
      fee: null,
      formState: {
        planId: null,
        warehouseType: warehouseTypeEnum.storageWarehouse,
        logisticsType: null,
        deliveryType: null,
      },
      boxList: [],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        hideOnSinglePage: true,
        showSizeChanger: false,
        size: 'small',
        position: 'both',
      },
      // 打印loading Id
      printLoadingId: null,
      // 打印产品标
      printProductLoading: false,
      payModalState: {
        visible: false,
        loading: false,
      },
      // [init]: 第一次支付，允许暂不支付; [repay]: 第二次支付，只允许立即支付;
      payType: null,
    });

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber"
        }
      },
      {
        width: 200,
        slots: {
          title: "boxNoTitle",
          customRender: "boxNo",
        }
      },
      { width: 50, },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.encasement_size"),
        slots: {
          customRender: "encasementSize"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.encasement_weight"),
        slots: {
          customRender: "encasementWeight"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        width: 150,
        slots: {
          title: "operationTitle",
          customRender: "operation"
        }
      },
    ];

    const innerColumns = [
      { width: 60, },
      {
        width: 450,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
       {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 150,
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        slots: {
          customRender: "operation"
        }
      },
    ];

    const getFullAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    // 确认发运
    const handleConfirmShipping = async (selectedPayWay, isPay) => {
      try {
        state.payModalState.loading = true;
        if (state.payType === 'init') {
          const data = Object.assign({}, state.formState, { isPay },{payFee:filters.amountToFixed2(state?.fee?.totalFee?.value)});
          await updateTransInfo(data);
        } else if (state.payType === 'repay') {
          const repayData = {
            planId: state.formState.planId,
            warehouseType: state.formState.warehouseType,
            payFee:filters.amountToFixed2(state.fee.totalFee.value)
          }
          await payInWarehousePlan(repayData);
        }
        
        state.payModalState.visible = false;
        message.success(vueI18n.t('common.succeed'));
        getFirstStepDetail();
      } catch (error) {
      } finally {
        state.payModalState.loading = false;
      }
    }

    // 打开支付弹窗
    const handleOpenPayModal = (payType) => {
      state.payType = payType;
      if (!state.formState.logisticsType) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.logistics_way')]));
        return;
      }
      if (!state.formState.deliveryType) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.delivery_way')]));
        return;
      }
      if (state.pageState.isNeedPay) {
        state.payModalState.visible = true;
        nextTick(() => {
          paymentRef.value.handleShowPayOrder();
        });
      } else {
        handleConfirmShipping(null, false);
      }
    }

    const handleOpenTrackModal = () => {
      const data = {
        planId: state.formState.planId,
        warehouseType: state.formState.warehouseType,
      }
      trackingShipmentsModalRef.value.open(data);
    }

    // 打印箱标
    const handleBoxPrint = async (detailId) => {
      try {
        const data = { id: state.formState.planId }
        let printUrl = '';
        if (detailId) {
          Object.assign(data, { detailId });
          state.printLoadingId = detailId;
          printUrl = getInWarehousePlanBoxPrint(data);
        } else {
          state.printLoadingId = 'allBox';
          printUrl = getInWarehpusePlanBoxBatchPrint(data);
        }
        await localPrint(printUrl);
      } catch (error) {
      } finally {
        state.printLoadingId = null;
      }
    }

    // 打印产品标
    const handleProductLabelPrint = async (item) => {
      try {
        state.printProductLoading = true;
        await localPrint(item.productLabelUrl);
      } catch (error) {
      } finally {
        state.printProductLoading = false;
      }
    }

    const handleToList = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/storage/inbound/list');
    }

    const getFirstStepDetail = () => {
      state.loading = true;
      getStorageDetail({ planId: state.formState.planId }).then(({ result }) => {
        setFormStateValue(state.pageState, result);
        setFormStateValue(state.formState, result);
        state.boxList = result.items;
        if (state.pageState.isNeedPay === true) {
          getPlanFees();
        }
      }).catch(() => {}).finally(() => {
        state.loading = false;
      });
    }

    const getPlanFees = () => {
      const data = {
        planId: state.formState.planId,
        warehouseType: state.formState.warehouseType,
      }
      getInWarehousePlanFee(data).then(({ result }) => {
        if (result) {
          state.fee = result;
        }
      }).catch(() => {})
    }

    onMounted(() => {
      if ('id' in route.params) {
        let id = route.params.id;
        state.formState.planId = id;
        getFirstStepDetail();
      }
    });

    return {
      ...toRefs(state),
      gToKg,
      cmCubicToM,
      getLinkman,
      logisticsWayEnum,
      deliveryWayEnum,
      paymentRef,
      trackingShipmentsModalRef,
      columns,
      innerColumns,
      getFullAddress,
      handleConfirmShipping,
      handleOpenPayModal,
      handleOpenTrackModal,
      handleBoxPrint,
      handleProductLabelPrint,
      handleToList,
    }
  }
})
</script>

<style scoped>

</style>