<!-- 追踪货件 -->
<template>
  <a-modal
    width="auto"
    v-model:visible="visible"
    :title="$t('warehouse.tracking_shipment')"
    :mask-closable="false"
    :centered="true"
    :confirm-loading="confirmLoading"
    @ok="handleConfirm"
  >
    <a-table
      :columns="columns"
      :data-source="tableData.tableList"
      :pagination="false"
      size="small"
      :expandIconAsCell="false"
      :expandIconColumnIndex="3"
      :loading="tableData.loading"
      :row-key="(record) => record.detailId"
    >
      <template #selfBox="{ record }">
        {{ record.selfBoxNo || '-' }}
      </template>
      <template #expandIcon="{ expanded, record, onExpand }">
        <CHaveProductInformation :disabled="!record.container?.hasRelationProduct" :open="expanded" @click="e => onExpand(record, e)" />
      </template>
      <template #trackNo="{ record }">
        <a-input v-if="record.isEdit" v-model:value="record.trackNo" allow-clear></a-input>
        <span v-else>{{ record.trackNo }}</span>
      </template>
      <template #expandedRowRender="{ record }">
        <a-table
          :columns="innerColumns"
          :data-source="record.items"
          :pagination="false"
          :row-key="(record, index) => index"
          size="small"
        >
          <template #productInfo="{ record }">
            <a-row :gutter="8" type="flex" align="middle">
              <a-col>
                <div class="table-list-img-common">
                  <c-image
                    :src="record.productImgUrl"
                    :thumbWidth="600"
                    :thumbHeight="600"
                  />
                </div>
              </a-col>
              <a-col flex="1">
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
                <div>SESKU: {{ record.seSku }}</div>
              </a-col>
            </a-row>
          </template>
          <template #encasementCount="{ record }">
            {{ record.containerCount }} Unit
          </template>
        </a-table>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { Col, Input, Row, Modal, Table, message } from 'ant-design-vue';
import CImage from '@/views/components/CImage.vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue';
import { useI18n } from "vue-i18n/index";
import { getAllTraceList, updateInWarehousePlanTraceNo } from '@/api/modules/consignment/inbound.js';
import { setFormStateValue } from '@/utils/general.js';

export default defineComponent({
  expose: ['open'],
  components: {
    AModal: Modal,
    ATable: Table,
    AInput: Input,
    ARow: Row,
    ACol: Col,
    CImage,
    CHaveProductInformation,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      confirmLoading: false,
      formState: {
        planId: null,
        warehouseType: null,
      },
      tableData: {
        tableList: [],
        loading: false,
      },
    });

    const columns = [
      {
        width: 60,
        title: () => vueI18n.t('warehouse.serial_number'),
        customRender: ({ index }) => {
          return index + 1
        }
      },
      {
        dataIndex: 'boxNo',
        width: 200,
        title: () => vueI18n.t('warehouse.box_no'),
      },
      {
        width: 200,
        title: () => vueI18n.t('common.user_defined_box_no'),
        slots: {
          customRender: 'selfBox'
        }
      },
      { width: 50, },
      {
        width: 250,
        title: () => vueI18n.t('warehouse.tracking_no'),
        slots: {
          customRender: 'trackNo'
        }
      },
    ];

    const innerColumns = [
      { width: 60, },
      {
        width: 450,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "encasementCount"
        }
      },
    ];

    const handleValidate = () => {
      let result = true;
      let items = state.tableData.tableList.map(item => {
        if (item.trackNo && item.isEdit) {
          let reg = /^[A-Za-z0-9\-]{0,35}$/;
          if (!reg.test(item.trackNo)) {
            result = false;
          } else {
            return {
              detailId: item.detailId,
              trackNo: item.trackNo,
            }
          }
        }
      }).filter(Boolean);
      return result ? items : result;
    }

    const handleConfirm = async () => {
      try {
        let items = handleValidate();
        if (!items) {
          message.error(vueI18n.t('common.p0_format_error', [vueI18n.t('warehouse.tracking_no')]));
          return;
        }
        if (items.length) {
          const data = Object.assign({}, state.formState, { items });
          state.confirmLoading = true;
          await updateInWarehousePlanTraceNo(data);
          message.success(vueI18n.t('common.succeed'));
        }
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const getList = () => {
      state.tableData.loading = true;
      const data = Object.assign({}, state.formState);
      getAllTraceList(data).then(({ result }) => {
        if (Array.isArray(result)) {
          state.tableData.tableList = result.map(item => {
            if (item.trackNo) {
              item.isEdit = false;
            } else {
              item.isEdit = true;
            }
            return item;
          });
        } else {
          state.tableData.tableList = [];
        }
      }).catch(() => {}).finally(() => {
        state.tableData.loading = false;
      })
    }

    const open = (data) => {
      state.visible = true;
      setFormStateValue(state.formState, data);
      getList();
    }

    const close = () => {
      state.visible = false;
    }

    return {
      ...toRefs(state),
      columns,
      innerColumns,
      handleConfirm,
      open,
      close,
    }
  }
})
</script>

<style scoped>

</style>